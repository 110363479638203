import { Box } from "@tokenterminal/ui/Box"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"
import Image from "next/image"
import { fadeInUpCss } from "../common/common.css"
import { customerGridCss, gridItemCss } from "./CustomerGrid.css"
import { BASE_PATH } from "@/constants/app.mjs"

const logoList = [
  "logo-coinbase_ventures.svg",
  "logo-bloomberg.svg",
  "logo-vaneck.svg",
  "logo-pantera.svg",
  "logo-bitwise.svg",
  "logo-market_vector.svg",
  "logo-delphi_digital.svg",
  "logo-grayscale.svg",
  "logo-brevan_howard.svg",
  "logo-dragonfly.svg",
  "logo-coindesk.svg",
  "logo-ark_invest.svg",
  "logo-arca.svg",
  "logo-goldentree.svg",
  "logo-falconx.svg",
  "logo-goldentree.svg",
]

const CustomerGrid = () => {
  const logos = logoList.slice(0, 15)

  return (
    <Stack
      alignItems="center"
      className={fadeInUpCss}
      paddingY="16x"
      style={{
        opacity: 0,
        animationDelay: "0.5s",
      }}
    >
      <Text
        color="muted"
        marginBottom={["4x", "6x"]}
        style={{
          maxWidth: "490px",
        }}
      >
        Trusted by
      </Text>
      <Box className={customerGridCss}>
        {logos.map((logo, index) => (
          <Image
            className={gridItemCss}
            alt={logo}
            src={`${BASE_PATH}/static/images/marketing/home/logos/${logo}`}
            width={520}
            height={500}
          />
        ))}
      </Box>
    </Stack>
  )
}

export default CustomerGrid
